const env = {
  NODE_ENV: process.env.REACT_APP_NODE_ENV as string,
  BASE_URL: process.env.REACT_APP_BASE_URL as string,
  IS_QA: process.env.REACT_APP_IS_QA as string | undefined,
  VERSION: process.env.REACT_APP_APP_VERTION as string,
  SAVE_KEY: process.env.REACT_APP_SAVE_KEY as string,
  REFRESH_TOKEN: process.env.REACT_APP_REFRESH_TOKEN_KEY as string,
};

export default env;
