import { ISortBys, ISortDirection } from "interfaces/service.interface";
import { useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export type State = ISortBys;
export type Action = (
  key: string,
  order: ISortDirection,
  isForce?: boolean
) => void;

const useSortBy = (
  defaultSort?: ISortBys
): {
  sortBys: State;
  setSortBys: Action;
  setMultiSortBys: Action;
  original: { [key: string]: ISortDirection };
  resetSortBys: () => void;
} => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [sortBys, setSortBys] = useState<State>(defaultSort || {});

  const sortByHandler = useCallback(
    (key: string, order: ISortDirection) => {
      const next = { [key]: order };
      const page = params.get("page");

      const search: string[] = [];
      if (page) search.push(`page=${page}`);
      if (order) search.push(`${key}=${order}`);

      navigate({
        search: search.join("&"),
      });

      setSortBys(next);
    },
    [navigate, params]
  );

  // 다중 정렬
  const multiSortByHandler = useCallback(
    (key: string, order: ISortDirection) => {
      const copy = { ...sortBys };
      const page = params.get("page");

      if (order === null) {
        delete copy[key];
      } else {
        copy[key] = order;
      }

      const search: string[] = [];

      if (page) search.push(`page=${page}`);

      for (let key in copy) {
        search.push(`${key}=${copy[key]}`);
      }

      navigate({
        search: search.join("&"),
      });

      setSortBys(copy);
    },
    [navigate, params, sortBys]
  );

  const resetSortBys = useCallback(() => {
    setSortBys({});
    navigate({ search: "" });
  }, [navigate]);

  const result = useMemo(() => {
    const base: { [key: string]: ISortDirection } = {};

    for (let key in sortBys) {
      const index = `sortBys[${key}]`;
      base[index] = sortBys[key];
    }

    return base;
  }, [sortBys]);

  return {
    sortBys: result,
    setSortBys: sortByHandler,
    setMultiSortBys: multiSortByHandler,
    original: sortBys,
    resetSortBys,
  };
};

export default useSortBy;

export const getSortBy = (keys: string[]) => {
  const searchParams = new URLSearchParams(window.location.search);
  const items: ISortBys = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = searchParams.get(key);

    if (value && ["DESC", "ASC"].includes(value)) {
      items[key] = value as "DESC" | "ASC";
    }
  }

  return items;
};
